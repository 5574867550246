import { Input, InputGroup, InputProps } from '@chakra-ui/react';
import { ForwardedRef, forwardRef } from 'react';

export const PasswordInput = forwardRef(
  (props: InputProps, ref: ForwardedRef<HTMLInputElement>) => {
    return (
      <InputGroup>
        <Input ref={ref} {...props} type="password" />
      </InputGroup>
    );
  },
);
