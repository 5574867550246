import { useEffect } from 'react';

import { DashboardApi, dashboardApi } from 'apis/dashboard';

import { IRatingOverview } from 'models/dashboard';

import { useQuery } from 'hooks/common';

export const useRatingOverview = () => {
  const [data, , , control] = useQuery<IRatingOverview, null, DashboardApi['getRatingOverview']>(
    null,
    dashboardApi.getRatingOverview,
    {
      preventInitCall: false,
    },
  );

  const rating = data?.average_rating;

  const reFetch = async () => {
    control.refetch();
  };

  const result: [typeof rating, typeof reFetch] = [rating, reFetch];
  return result;
};
