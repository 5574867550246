import { useMemo } from 'react';

import { DashboardApi, dashboardApi } from 'apis/dashboard';

import { IJobOverview } from 'models/dashboard';
import { JobStatus, WorkerStatus } from 'models/job';

import { useQuery } from 'hooks/common';
import { useTranslate } from 'hooks/common';
import { useJobStatusLabels, useWorkerStatusLabels } from 'hooks/components/job';

export const useJobOverview = () => {
  const [data, , , control] = useQuery<IJobOverview, null, DashboardApi['getJobOverview']>(
    null,
    dashboardApi.getJobOverview,
    {
      preventInitCall: false,
    },
  );

  const t = useTranslate();

  const jobStatusLabels = useJobStatusLabels();

  const slotUserLabels = useWorkerStatusLabels();

  const {
    total_active_jobs,
    total_open_jobs,
    total_completed_jobs_of_current_month,
    total_cancelled_jobs,
    job_charts: jobs,
  } = data || {};

  const jobData = useMemo(() => {
    return jobs?.map((data) => {
      const isJodJobType = data?.type === 1; // if not, it means no show. it use worker status, not job status type

      return {
        name: isJodJobType
          ? data?.status === JobStatus.AllRejected
            ? t('value.rejected')
            : jobStatusLabels[data?.status]
          : slotUserLabels[WorkerStatus.NoShow],
        data: data?.data?.map((monthData) => monthData.total_jobs),
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const timeData = useMemo(() => {
    return jobs?.at(0)?.data?.map((monthData) => [monthData.month, monthData.year]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const reFetch = async (params: { locationId?: number; startDate?: string; endDate?: string }) => {
    control.refetch(params);
  };

  const result: [
    {
      total_active_jobs?: number;
      total_open_jobs?: number;
      total_completed_jobs_of_current_month?: number;
      total_cancelled_jobs?: number;
      jobData?: { name: string; data: number[] }[];
      timeData?: string[][];
    },
    typeof reFetch,
  ] = [
    {
      total_active_jobs,
      total_open_jobs,
      total_completed_jobs_of_current_month,
      total_cancelled_jobs,
      jobData,
      timeData,
    },
    reFetch,
  ];

  return result;
};
