import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import floor from 'lodash/floor';
import round from 'lodash/round';

dayjs.extend(utc);
dayjs.extend(timezone);

export const getEndOfToday = () => dayjs.tz(Date.now()).endOf('day');

export const getEndOfBeforeToday = () => dayjs.tz(Date.now()).subtract(1, 'day').endOf('day');

export const convertTimeToHourMinute = (time: number, unit: 's' | 'm'): [number, number] => {
  if (time <= 0) return [0, 0];

  const second = unit === 's' ? time : time * 60;
  const minute = second / 60;
  const hours = floor(minute / 60);
  const restMinute = round(minute % 60);
  return [hours, restMinute];
};

export const convertTimeToHour = (time: number, unit: 's' | 'm'): number => {
  if (time === 0) return 0;
  const second = unit === 's' ? time : time * 60;
  const minute = second / 60;
  const hours = round(minute / 60, 2);
  return hours;
};

export const displayHourMinute = (h: number, m: number) => {
  const hour = String(h).padStart(2, '0');
  const minute = String(m).padStart(2, '0');

  return `${hour}:${minute}`;
};

export function isValidDateString(dateString: string): boolean {
  // Regular expression to match datetime format
  const datetimeRegex = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/;

  // Check if the string matches the datetime format
  if (datetimeRegex.test(dateString)) {
    return true;
  }

  // Convert to datetime and check if it's valid
  const date = new Date(dateString);
  return !Number.isNaN(date.getTime());
}
