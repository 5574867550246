/* eslint-disable react-hooks/exhaustive-deps */
import { Alert, AlertIcon, Box } from '@chakra-ui/react';
import { PATH_NAME } from 'configs';
import { FC, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { isTwoFaVerifiedSelector } from 'store/modules/auth';
import { locationProfileActions } from 'store/modules/location-profile';

import { Role } from 'models/user';

import { SystemPermission } from 'types/permission';

import { Loading } from 'components/molecules';
import { RedirectFirstPage } from 'components/organisms/NavMenu/RedirectFirstPage';

import { useRouter, useTranslate } from 'hooks/common';
import { useCurrentPermission, useCurrentRole, useCurrentUser } from 'hooks/components/auth';

export type RolePermissionRouteGuardProps = {
  requiredRoles?: Role[];
  requiredPermissions?: SystemPermission[];
};

export const RolePermissionRouteGuard: FC<RolePermissionRouteGuardProps> = ({
  requiredRoles,
  requiredPermissions,
  children,
  ...props
}) => {
  const router = useRouter();
  const t = useTranslate();
  const dispatch = useDispatch();
  const currentRole = useCurrentRole();
  const [currentPermission, checKHasPermission] = useCurrentPermission();
  const currentUser = useCurrentUser();
  const isTwoFaVerified = useSelector(isTwoFaVerifiedSelector);
  const react2fa = process.env.REACT_APP_TFA_AUTHENTICATION;
  const partnerRoles = [
    Role.HqManager,
    Role.AreaManager,
    Role.AreaManagerApproval,
    Role.LocationManager,
    Role.SuperHqInternal,
    Role.SuperHqExternal,
  ];

  const shouldUse2FA =
    react2fa === 'ALL' ||
    (react2fa === 'INTERNAL' &&
      currentUser?.user_type &&
      !partnerRoles.includes(currentUser.user_type)) ||
    (react2fa === 'PARTNER' &&
      currentUser?.user_type &&
      partnerRoles.includes(currentUser.user_type));
  const isAuthorized = useMemo(() => {
    if (!currentRole || !currentPermission) return false;
    const isValidRole = requiredRoles?.length ? requiredRoles.includes(currentRole) : true;
    const isValidPermission = requiredPermissions?.length
      ? checKHasPermission(requiredPermissions)
      : true;

    return isValidRole && isValidPermission;
  }, [checKHasPermission, currentPermission, currentRole, requiredPermissions, requiredRoles]);

  useEffect(() => {
    if (currentRole === Role.LocationManager) {
      dispatch(locationProfileActions.getLocationProfileInfo());
    }
  }, [dispatch, currentRole]);

  // useEffect(() => {
  //   if (!isTwoFaVerified && shouldUse2FA) {
  //     if (
  //       router.pathname !== PATH_NAME.SELECT_2FA_METHOD &&
  //       router.pathname !== PATH_NAME.AUTH_OTP &&
  //       router.pathname !== PATH_NAME.AUTH_OTP_QR &&
  //       router.pathname !== PATH_NAME.SUCCESSFUL_LOGIN
  //     ) {
  //       if (currentUser?.tfa_method !== null && currentUser?.tfa_verified === false) {
  //         if (currentUser?.tfa_method === 'SMS' || currentUser?.tfa_method === 'EMAIL') {
  //           router.push(PATH_NAME.AUTH_OTP);
  //         } else {
  //           router.push(PATH_NAME.AUTH_OTP_QR);
  //         }
  //       } else {
  //         if (currentUser?.tfa_verified === true) {
  //           router.push(PATH_NAME.SUCCESSFUL_LOGIN);
  //         } else {
  //           router.push(PATH_NAME.SELECT_2FA_METHOD);
  //         }
  //       }
  //     }
  //   }
  // }, [isTwoFaVerified, router, currentUser?.tfa_method, shouldUse2FA]);

  useEffect(() => {
    if (!isTwoFaVerified && shouldUse2FA) {
      const redirectTo2FA = () => {
        if (currentUser?.tfa_method !== null && currentUser?.tfa_verified === false) {
          if (currentUser.tfa_method === 'SMS' || currentUser.tfa_method === 'EMAIL') {
            router.push(PATH_NAME.AUTH_OTP);
          } else {
            router.push(PATH_NAME.AUTH_OTP_QR);
          }
        } else {
          if (currentUser?.tfa_verified === true) {
            router.push(PATH_NAME.SUCCESSFUL_LOGIN);
          } else {
            router.push(PATH_NAME.SELECT_2FA_METHOD);
          }
        }
      };

      const pathsToExclude = [
        PATH_NAME.SELECT_2FA_METHOD,
        PATH_NAME.AUTH_OTP,
        PATH_NAME.AUTH_OTP_QR,
        PATH_NAME.SUCCESSFUL_LOGIN,
      ];

      if (!pathsToExclude.includes(router.pathname)) {
        redirectTo2FA();
      }
    }
  }, [isTwoFaVerified, router, currentUser?.tfa_method, shouldUse2FA]);

  if (!isTwoFaVerified && shouldUse2FA) {
    if (
      router.pathname === PATH_NAME.SELECT_2FA_METHOD ||
      router.pathname === PATH_NAME.AUTH_OTP ||
      router.pathname === PATH_NAME.AUTH_OTP_QR ||
      router.pathname === PATH_NAME.SUCCESSFUL_LOGIN
    ) {
      return <>{children}</>;
    } else {
      return <Loading h="100%" loading tip="Redirecting to 2FA..." {...props} />;
    }
  }

  if (!currentRole || !currentPermission) {
    return <Loading h="100vh" loading tip="Authorizing..." {...props} />;
  }

  if (isAuthorized === true) {
    return <>{children}</>;
  }

  if (router.pathname === PATH_NAME.ROOT) {
    return <RedirectFirstPage />;
  }

  return (
    <Box p="20px">
      <Alert status="error">
        <AlertIcon />
        {t('message.youNoPermissionAccessPage')}
      </Alert>
    </Box>
  );
};
