import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { creditOverviewSelector, dashboardActions } from 'store/modules/dashboard';

export const useCreditOverview = () => {
  const dispatch = useDispatch();
  const creditOverview = useSelector(creditOverviewSelector);

  const refetch = async () => {
    dispatch(dashboardActions.getCreditOverview());
  };

  const consumedCredit = useMemo(() => {
    return creditOverview?.map((entity) => entity.consumed_credits);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creditOverview]);

  const receivedCredit = useMemo(() => {
    return creditOverview?.map((entity) => entity.received_credits);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creditOverview]);

  const timeData = useMemo(() => {
    return creditOverview?.map((entity) => [entity.month, entity.year]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creditOverview]);

  useEffect(() => {
    dispatch(dashboardActions.getCreditOverview());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const result: [
    {
      consumedCredit?: number[];
      receivedCredit?: number[];
      timeData?: string[][];
    },
    typeof refetch,
  ] = [
    {
      consumedCredit,
      receivedCredit,
      timeData,
    },
    refetch,
  ];

  return result;
};
