import { httpClient } from 'apis';
import { AxiosResponse } from 'axios';
import { DATE_BE, DATE_TIME_BE } from 'constant';
import i18next from 'i18next';
import { DataResponse, ListAxiosResponse, ObjectAxiosResponse, Order, Payload, Query } from 'types';
import { removeEmpty, removeMultipleWhitespace, removeNullish } from 'utils';

import { ICreditHistory, ICreditLog, IDebitCreditHistory } from 'models/credit';
import { ILocationCreditHistory } from 'models/location';
import { Role } from 'models/user';

import { CreditHistoryByAreaFilterData } from 'components/molecules';
import { CompanyCreditHistoryFilterData } from 'components/molecules/CompanyCreditHistoryFilter';
import { CompanyDebitCreditFilterData } from 'components/molecules/CompanyDebitCreditFilter';
import { AssignCompanyCreditFormData } from 'components/organisms/CompanyCreditAssignForm';
import { AssignLocationCreditFormData } from 'components/organisms/LocationCreditDetail';

import { getPathByRole, mapErrorDescriptions, mapPaginationState, mapQueryToParams } from './utils';

const entity = 'credit';

const basePath = `portal/${entity}` as const;

const creditCompanyPayloadScheme = {
  company_id: 'company',
  date: 'date',
  assigned_credits: 'assigningAmount',
  comment: 'comment',
};
const creditLocationPayloadScheme = {
  assigned_credits: 'amount',
  comment: 'comment',
};

export const creditApi = {
  async assignCompanyCredit(
    payload: Payload<AssignCompanyCreditFormData>,
  ): Promise<DataResponse<null>> {
    try {
      const { body } = payload;
      const res: AxiosResponse<{ message: string }> = await httpClient.post(
        `${basePath}/admin/assign`,
        {
          company_id: body.company?.value,
          date: body.date?.set('second', 0).format(DATE_TIME_BE),
          assigned_credits: body.assigningAmount,
          comment: body.comment,
        },
      );

      return {
        message: res.data.message,
        status: true,
      };
    } catch (error: any) {
      const { description, message = i18next.t<string>('message.assignCreditUnsuccess') } = error;
      return Promise.reject({
        status: false,
        message,
        description: mapErrorDescriptions(creditCompanyPayloadScheme, description),
      });
    }
  },
  async companyCreditHistory(
    payload: Payload<
      null,
      null,
      Query & Partial<CompanyCreditHistoryFilterData> & { companyId?: string | number }
    >,
  ): Promise<DataResponse<ICreditLog[]>> {
    try {
      const { pagination, query } = payload;
      const { sort, search, dateRange, minAssign, maxAssign, company, companyId } = query || {};
      const [startDate, endDate] = dateRange || [];

      const {
        data: { data, message },
      }: ListAxiosResponse<ICreditLog> = await httpClient.get(`${basePath}/history`, {
        params: mapQueryToParams(
          { sort, search },
          pagination,
          {
            assign_start_date: startDate?.second(0).format(DATE_TIME_BE),
            assign_end_date: endDate?.second(59).format(DATE_TIME_BE),
            company_id: company?.value ?? companyId,
            min_assign_amount: minAssign,
            max_assign_amount: maxAssign,
          },
          {
            sortDefaultByCreatedAt: true,
          },
        ),
      });

      return {
        status: true,
        data: data.data,
        pagination: mapPaginationState(data),
        message: message,
      };
    } catch (error: any) {
      return Promise.reject({ status: false, message: error.message });
    }
  },
  async companyCreditHistoryById(
    payload: Payload<
      null,
      null,
      Query & Partial<CompanyCreditHistoryFilterData> & { companyId: string | number }
    >,
  ): Promise<DataResponse<ICreditLog[]>> {
    try {
      const { pagination, query } = payload;
      const { sort, search, dateRange, minAssign, maxAssign, companyId } = query || {};
      const [startDate, endDate] = dateRange || [];
      const {
        data: { data, message },
      }: ListAxiosResponse<ICreditLog> = await httpClient.get(`${basePath}/company`, {
        params: mapQueryToParams(
          { sort, search },
          pagination,
          {
            company_id: companyId,
            assign_start_date: startDate?.format(DATE_BE),
            assign_end_date: endDate?.format(DATE_BE),
            min_assign_amount: minAssign,
            max_assign_amount: maxAssign,
          },
          {
            sortDefaultByCreatedAt: true,
          },
        ),
      });

      return {
        status: true,
        data: data.data,
        pagination: mapPaginationState(data),
        message: message,
      };
    } catch (error: any) {
      return Promise.reject({ status: false, message: error.message });
    }
  },
  async locationCreditHistoryById(
    payload: Payload<null, { id: string | number }, Query>,
  ): Promise<DataResponse<ILocationCreditHistory[]>> {
    try {
      const { pagination, query, params } = payload;
      const { sort } = query || {};
      const {
        data: { data, message },
      }: ListAxiosResponse<ILocationCreditHistory> = await httpClient.get(
        `${basePath}/area-user/credit-history-of-locations-with-location-id`,
        {
          params: mapQueryToParams(
            { sort },
            pagination,
            {
              location_id: params.id,
            },
            {
              sortDefault: {
                key: 'date_credit',
                field: 'date_credit',
                order: Order.Descend,
              },
            },
          ),
        },
      );
      return {
        status: true,
        data: data.data,
        pagination: mapPaginationState(data),
        message: message,
      };
    } catch (error: any) {
      return Promise.reject({ status: false, message: error.message });
    }
  },
  async exportCompanyCreditHistory(
    payload: Payload<
      null,
      null,
      Query & Partial<CompanyCreditHistoryFilterData> & { companyId?: number | string }
    >,
  ): Promise<DataResponse<ICreditLog[]>> {
    try {
      const { query } = payload;
      const { search, company, minAssign, maxAssign, dateRange, companyId } = query || {};

      const [startDate, endDate] = dateRange || [];

      const res = await httpClient.get(`${basePath}/historyExport`, {
        params: removeNullish(
          removeEmpty({
            assign_start_date: startDate?.second(0).format(DATE_TIME_BE),
            assign_end_date: endDate?.second(59).format(DATE_TIME_BE),
            company_id: company?.value ?? companyId,
            min_assign_amount: minAssign,
            max_assign_amount: maxAssign,
            key_word: removeMultipleWhitespace(search),
          }),
        ),
        responseType: 'arraybuffer',
      });
      return {
        status: true,
        data: res.data,
        message: i18next.t<string>('message.exportSuccess'),
      };
    } catch (error: any) {
      return Promise.reject({ status: false, message: error.message });
    }
  },
  async companyDebitCreditHistory(
    payload: Payload<
      null,
      null,
      Query & Partial<CompanyDebitCreditFilterData> & { companyId?: string | number }
    >,
  ): Promise<DataResponse<IDebitCreditHistory[]>> {
    try {
      const { pagination, query } = payload;
      const { sort, search, dateRange, company, companyId } = query || {};
      const [startDate, endDate] = dateRange || [];

      const {
        data: { data, message },
      }: ListAxiosResponse<IDebitCreditHistory> = await httpClient.get(
        `${basePath}/list-debit-credit`,
        {
          params: mapQueryToParams(
            { sort, search },
            pagination,
            {
              assign_start_date: startDate?.second(0).format(DATE_TIME_BE),
              assign_end_date: endDate?.second(59).format(DATE_TIME_BE),
              company_id: company?.value ?? companyId,
            },
            {
              sortDefaultByCreatedAt: true,
            },
          ),
        },
      );

      return {
        status: true,
        data: data.data,
        pagination: mapPaginationState(data),
        message: message,
      };
    } catch (error: any) {
      return Promise.reject({ status: false, message: error.message });
    }
  },
  async exportCompanyDebitCreditHistory(
    payload: Payload<
      null,
      null,
      Query & Partial<CompanyCreditHistoryFilterData> & { companyId?: number | string }
    >,
  ): Promise<DataResponse<IDebitCreditHistory[]>> {
    try {
      const { query } = payload;
      const { search, company, dateRange, companyId } = query || {};

      const [startDate, endDate] = dateRange || [];

      const res = await httpClient.get(`${basePath}/debit-creditExport`, {
        params: removeNullish(
          removeEmpty({
            assign_start_date: startDate?.second(0).format(DATE_TIME_BE),
            assign_end_date: endDate?.second(59).format(DATE_TIME_BE),
            company_id: company?.value ?? companyId,
            key_word: removeMultipleWhitespace(search),
          }),
        ),
        responseType: 'arraybuffer',
      });
      return {
        status: true,
        data: res.data,
        message: i18next.t<string>('message.exportSuccess'),
      };
    } catch (error: any) {
      return Promise.reject({ status: false, message: error.message });
    }
  },
  async exportLocationCreditHistory(): Promise<DataResponse<ICreditLog[]>> {
    try {
      const authorizedPath = getPathByRole({
        [Role.HqManager]: 'hq-user/download-excel-credit-history-of-locations',
        [Role.AreaManager]: 'area-user/download-excel-credit-history-of-locations',
        [Role.LocationManager]: 'location-manager/download-excel-credit-history-of-locations',
      });
      if (!authorizedPath) {
        throw new Error(i18next.t<string>('message.haveNoPermissionAccessFeature'));
      }
      const res = await httpClient.get(`${basePath}/${authorizedPath}`, {
        responseType: 'arraybuffer',
      });

      return {
        status: true,
        data: res.data,
        message: i18next.t<string>('message.exportSuccess'),
      };
    } catch (error: any) {
      return Promise.reject({ status: false, message: error.message });
    }
  },
  async assignLocationCredit(
    payload: Payload<AssignLocationCreditFormData & { locationId: number }>,
  ): Promise<DataResponse<{ availableCredits: number }>> {
    try {
      const { body } = payload;
      const res: ObjectAxiosResponse<{ available_credits: number }> = await httpClient.post(
        `${basePath}/hq/assign`,
        {
          assigned_credits: body.amount,
          comment: body.comment,
          location_id: body.locationId,
        },
      );
      return {
        message: res.data.message,
        status: true,
        data: {
          availableCredits: res.data.data.available_credits,
        },
      };
    } catch (error: any) {
      const { description, message = i18next.t<string>('message.assignCreditUnsuccess') } = error;
      return Promise.reject({
        status: false,
        message,
        description: mapErrorDescriptions(creditLocationPayloadScheme, description),
      });
    }
  },
  async getAvailableCreditCompany(): Promise<DataResponse<{ availableCredits: number }>> {
    try {
      const res: ObjectAxiosResponse<{ available_credits: number }> = await httpClient.get(
        `${basePath}/jodCreditAvailableHq`,
      );
      return {
        message: '',
        status: true,
        data: {
          availableCredits: res.data.data.available_credits,
        },
      };
    } catch (error: any) {
      const { description, message = i18next.t<string>('message.actionFailed') } = error;
      return Promise.reject({
        status: false,
        message,
        description: mapErrorDescriptions(creditLocationPayloadScheme, description),
      });
    }
  },
  async hqCreditHistory(
    payload: Payload<null, null, Query & Partial<CreditHistoryByAreaFilterData>>,
  ): Promise<DataResponse<ICreditLog[]>> {
    try {
      const { pagination, query } = payload;
      const {
        sort,
        search,
        recent,
        dateRange,
        minAvailableCredit,
        maxAvailableCredit,
        minAssignedCredit,
        maxAssignedCredit,
      } = query || {};
      const [startDate, endDate] = dateRange || [];

      const {
        data: { data, message },
      }: ListAxiosResponse<ICreditLog> = await httpClient.get(
        `${basePath}/hq/allCreditHistoryLocationByHq`,
        {
          params: mapQueryToParams(
            { sort, search },
            pagination,
            {
              recent: recent ? 1 : '',
              start_date: startDate?.format(DATE_BE),
              end_date: endDate?.format(DATE_BE),
              min_available_credit: minAvailableCredit,
              max_available_credit: maxAvailableCredit,
              min_assign_amount: minAssignedCredit,
              max_assign_amount: maxAssignedCredit,
            },
            { sortDefaultByCreatedAt: true },
          ),
        },
      );

      return {
        status: true,
        data: data.data,
        pagination: mapPaginationState(data),
        message: message,
      };
    } catch (error: any) {
      return Promise.reject({ status: false, message: error.message });
    }
  },
  async getTotalLocationAvailableCreditByCompany(): Promise<
    DataResponse<{ totalAvailableCredits: number }>
  > {
    try {
      const res: ObjectAxiosResponse<{ available_credit_total: number }> = await httpClient.get(
        `${basePath}/hq/availableCreditsTotalByHq`,
      );

      return {
        message: '',
        status: true,
        data: {
          totalAvailableCredits: res.data.data.available_credit_total,
        },
      };
    } catch (error: any) {
      const { description, message = i18next.t<string>('message.actionFailed') } = error;
      return Promise.reject({
        status: false,
        message,
        description: mapErrorDescriptions(creditLocationPayloadScheme, description),
      });
    }
  },
  async getTotalLocationAvailableCredit(): Promise<
    DataResponse<{ totalAvailableCredits: number }>
  > {
    try {
      const authorizedPath = getPathByRole({
        [Role.HqManager]: 'hq/availableCreditsTotalByHq',
        [Role.AreaManager]: 'area-user/available-credits-total-by-area-user',
      });
      if (!authorizedPath) {
        throw new Error(i18next.t<string>('message.haveNoPermissionAccessFeature'));
      }
      const res: ObjectAxiosResponse<{ available_credit_total: number }> = await httpClient.get(
        `${basePath}/${authorizedPath}`,
      );
      return {
        message: '',
        status: true,
        data: {
          totalAvailableCredits: res.data.data.available_credit_total,
        },
      };
    } catch (error: any) {
      const { description, message = i18next.t<string>('message.actionFailed') } = error;
      return Promise.reject({
        status: false,
        message,
        description: mapErrorDescriptions(creditLocationPayloadScheme, description),
      });
    }
  },
  async locationAreaCreditHistory(
    payload: Payload<null, null, Query & Partial<CreditHistoryByAreaFilterData>>,
  ): Promise<DataResponse<ICreditHistory[]>> {
    try {
      const { pagination, query } = payload;
      const {
        sort,
        search,
        dateRange,
        recent,
        minAvailableCredit,
        maxAvailableCredit,
        minAssignedCredit: minAvailableConsumed,
        maxAssignedCredit: maxAvailableConsumed,
      } = query || {};
      const [startDate, endDate] = dateRange || [];
      const {
        data: { data, message },
      }: ListAxiosResponse<ICreditHistory> = await httpClient.get(
        `${basePath}/area-user/credit-history-of-locations`,
        {
          params: mapQueryToParams(
            { sort, search },
            pagination,
            {
              recent: recent ? 1 : '',
              start_date: startDate?.second(0).format(DATE_TIME_BE),
              end_date: endDate?.second(59).format(DATE_TIME_BE),
              min_available_credit: minAvailableCredit,
              max_available_credit: maxAvailableCredit,
              min_assign_amount: minAvailableConsumed,
              max_assign_amount: maxAvailableConsumed,
            },
            { sortDefault: { key: 'date_credit', field: 'date_credit', order: Order.Descend } },
          ),
        },
      );

      return {
        status: true,
        data: data.data,
        pagination: mapPaginationState(data),
        message: message,
      };
    } catch (error: any) {
      return Promise.reject({ status: false, message: error.message });
    }
  },
  async exportCompanyCreditHistoryById(
    payload: Payload<
      null,
      null,
      Query & Partial<CompanyCreditHistoryFilterData> & { companyId: number | string }
    >,
  ): Promise<DataResponse<ICreditLog[]>> {
    try {
      const { query } = payload;
      const { search, minAssign, maxAssign, dateRange, companyId } = query || {};

      const [startDate, endDate] = dateRange || [];

      const res = await httpClient.get(`${basePath}/companyHistoryExport`, {
        params: removeNullish(
          removeEmpty({
            assign_start_date: startDate?.second(0).format(DATE_TIME_BE),
            assign_end_date: endDate?.second(59).format(DATE_TIME_BE),
            company_id: companyId,
            min_assign_amount: minAssign,
            max_assign_amount: maxAssign,
            key_word: removeMultipleWhitespace(search),
          }),
        ),
        responseType: 'arraybuffer',
      });
      return {
        status: true,
        data: res.data,
        message: i18next.t<string>('message.exportSuccess'),
      };
    } catch (error: any) {
      return Promise.reject({ status: false, message: error.message });
    }
  },
};

export type CreditApi = typeof creditApi;
